import React, { useRef } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/link-ws';
import { setContext } from '@apollo/link-context';
import { useAuth0 } from './react-auth0-spa';

export const ApolloClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { getTokenSilently } = useAuth0();

  const apolloClient = useRef(
    new ApolloClient({
      cache: new InMemoryCache(),
      link: setContext(async (_, { headers }) => {
        const token = await getTokenSilently();
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      }).concat(
        split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          new WebSocketLink({
            uri: 'wss://smiling-cougar-37.hasura.app/v1/graphql',
            options: {
              reconnect: true,
              connectionParams: async () => {
                const token = await getTokenSilently();
                return {
                  headers: { authorization: token ? `Bearer ${token}` : '' },
                };
              },
            },
          }),
          new HttpLink({
            uri: 'https://smiling-cougar-37.hasura.app/v1/graphql',
          }),
        ),
      ),
    }),
  );

  return (
    <ApolloProvider client={apolloClient.current}>{children}</ApolloProvider>
  );
};
