import React from 'react';

import { ApolloClientProvider } from './ApolloClientProvider';
import './App.css';
import { SignalStrength } from './SignalStrength/SignalStrength';
import { Auth0Provider } from './react-auth0-spa';
import history from './utils/history';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { NavBar } from './NavBar/NavBar';

const onRedirectCallback = (result?: RedirectLoginResult) => {
  history.push(
    result?.appState.targetUrl ?? {
      pathname: window.location.pathname,
      search: '',
      hash: '',
    },
  );
};

function App() {
  return (
    <Auth0Provider
      domain="dev-1u76du43.auth0.com"
      client_id="O43NAacTJuA1Zx2ATWg45NxoM4XcsX9x"
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      audience="https://smiling-cougar-37.hasura.app"
    >
      <ApolloClientProvider>
        <NavBar />
        <SignalStrength />
      </ApolloClientProvider>
    </Auth0Provider>
  );
}

export default App;
