/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../__generated__/globalTypes';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type RecentSignalStrengthSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type RecentSignalStrengthSubscription = {
  __typename?: 'subscription_root';
} & {
  signal_strength: Array<
    { __typename?: 'signal_strength' } & Pick<
      Types.Signal_Strength,
      'id' | 'reported_at' | 'rssi' | 'snr'
    >
  >;
};

export const RecentSignalStrengthDocument = gql`
  subscription RecentSignalStrength {
    signal_strength(order_by: { reported_at: desc_nulls_last }) {
      id
      reported_at
      rssi
      snr
    }
  }
`;

/**
 * __useRecentSignalStrengthSubscription__
 *
 * To run a query within a React component, call `useRecentSignalStrengthSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecentSignalStrengthSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentSignalStrengthSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRecentSignalStrengthSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    RecentSignalStrengthSubscription,
    RecentSignalStrengthSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    RecentSignalStrengthSubscription,
    RecentSignalStrengthSubscriptionVariables
  >(RecentSignalStrengthDocument, baseOptions);
}
export type RecentSignalStrengthSubscriptionHookResult = ReturnType<
  typeof useRecentSignalStrengthSubscription
>;
export type RecentSignalStrengthSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  RecentSignalStrengthSubscription
>;
