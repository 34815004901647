import React from 'react';
import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import TimeAgo from 'react-timeago';
import { useRecentSignalStrengthSubscription } from './__generated__/SignalStrength';

gql`
  subscription RecentSignalStrength {
    signal_strength(order_by: { reported_at: desc_nulls_last }) {
      id
      reported_at
      rssi
      snr
    }
  }
`;

export const SignalStrength = () => {
  const { data } = useRecentSignalStrengthSubscription();
  if (!data) {
    return null;
  }

  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <td style={{ width: '30%' }}>Time</td>
          <td style={{ width: '30%' }}>RSSI</td>
          <td style={{ width: '30%' }}>SNR</td>
        </tr>
      </thead>
      <tbody>
        {data.signal_strength.map((log) => (
          <tr key={log.id}>
            <td
              style={{ width: '30%' }}
              title={DateTime.fromISO(log.reported_at).toLocaleString(
                DateTime.DATETIME_MED_WITH_SECONDS,
              )}
            >
              <TimeAgo date={log.reported_at} />
            </td>
            <td style={{ width: '30%' }}>{log.rssi}</td>
            <td style={{ width: '30%' }}>{log.snr}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
